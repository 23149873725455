import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { Deal } from '../../../../data/Deals';
import { dropdownMenuWithdrawData } from '../../../../data/selects/DropdownMenuWithdrawData';
import { dealData } from '../../../../data/states/DealData';
import { DEAL_REVIEW } from '../../../../data/states/States';
import { setActiveWithdrawState } from '../../../../features/activeState/activeWithdrawStateSlice';
import { setReceiveSum } from '../../../../features/receiveSumSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { EstimatedIncome } from '../../../estimatedComponents/EsimatedIncome';
import { USDTIcon, WalletIcon } from '../../../Icons';
import { NotificationSmall } from '../../../notifications/NotificationSmall';
import { DropdownMenuWithdraw } from '../../../selects/DropdownMenuWithdraw';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setEstimatedIncome } from '../../../../features/estimatedIncomeSlice';


export function DealState() {
  const dropdownMenuWithdrawValue: Deal = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);
  const estimatedIncome = useSelector((state: RootState) => state.estimatedIncome.value);
  const receiveSum = useSelector((state: RootState) => state.receiveSum.value);

  const [list, setList] = React.useState<Array<Deal>>([{
    icon: USDTIcon(),
    name: "USDT",
    count: "0",
  }]);

  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(setActiveWithdrawState(DEAL_REVIEW));
  }

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const getWithdrawBalance = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];

      try {
        //Баланс
        await contract.methods.balanceOf(fromAddress).call({from: fromAddress})
        .then(function(result : any){
          result = web3.utils.fromWei(result, 'ether');
          const balanceDsf = result;
          console.log("Address = " + fromAddress);
          console.log("Balance = " + result);
          
          //totalSupply
          contract.methods.totalSupply().call({from: fromAddress})
          .then(function(totalSupplyDsf : any){
            totalSupplyDsf = web3.utils.fromWei(totalSupplyDsf, 'ether');
            console.log("Total Supply = " + totalSupplyDsf);
            
            
            //contract.methods.totalDeposited().call({from: fromAddress})
            contract.methods.totalHoldings().call({from: fromAddress})
            .then(function(totalDepositedDsf : any){
              totalDepositedDsf = web3.utils.fromWei(totalDepositedDsf, 'ether');
              console.log("Total Deposited = " + totalDepositedDsf);

              contract.methods.lpPrice().call({from: fromAddress})
              .then(function(lpPriceDsf : any){
                lpPriceDsf = web3.utils.fromWei(lpPriceDsf, 'ether');
                console.log("Lp Price = " + lpPriceDsf);  
                console.log("ver.Mk2");
                
                const realBalance = parseFloat(totalDepositedDsf) * parseFloat(balanceDsf) / parseFloat(totalSupplyDsf);
                console.log("Real balance in USDT = " + realBalance);
                
                const realEstimateIncome = parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - realBalance;
                console.log("Real Estimate Income = " + realEstimateIncome);

                let realBalanceStr = String(realBalance);


                // ВРЕМЕННОЕ РЕШЕНИЕ
                // для каждого адреса закостылены (прописаны) значения
                // чтобы находить правильное количество дохода
                //let lpPriceDsfStartForAddress = "1000000000000000000";

                //0xa7c430a74732ef683d415a981a9869eeee30be08
                if (fromAddress == "0xa7c430a74732ef683d415a981a9869eeee30be08") {
                  //lpPriceDsfStartForAddress = "1000000000000000000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 239.8;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x296fa06e33cb183709e2181878580e309482b446
                else if (fromAddress == "0x296fa06e33cb183709e2181878580e309482b446") {
                  //lpPriceDsfStartForAddress = "1004495309461100000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 2000;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x9a8d6a4fc3a4bc6d0974a24337b0a007f1bc4ec2
                else if (fromAddress == "0x9a8d6a4fc3a4bc6d0974a24337b0a007f1bc4ec2") {
                  //lpPriceDsfStartForAddress = "1004495309461100000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 1;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x7cd9ff84abf2b23e825154aca2a62fafe185bd5e
                else if (fromAddress == "0x7cd9ff84abf2b23e825154aca2a62fafe185bd5e") {
                  //lpPriceDsfStartForAddress = "1034807257180060000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 77;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x36fa76f005de23cec94f9d3cc7cbf06a02a69ec9
                else if (fromAddress == "0x36fa76f005de23cec94f9d3cc7cbf06a02a69ec9") {
                  //lpPriceDsfStartForAddress = "1034807257180060000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 9996.27;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x0f63994c3493b4198029e8cf644076526857c038
                else if (fromAddress == "0x0f63994c3493b4198029e8cf644076526857c038") {
                  //lpPriceDsfStartForAddress = "1013005048503070000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 10005.82;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x4495661bac8d90b28105fb2268b1a27241553962
                /*else if (fromAddress == "0x4495661bac8d90b28105fb2268b1a27241553962") {
                  lpPriceDsfStartForAddress = "1013005048503070000";
                  lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 50;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }*/
                //0x742cfa44c6f30033335b07bc1548a40d69dffb0d
                else if (fromAddress == "0x742cfa44c6f30033335b07bc1548a40d69dffb0d") {
                  //lpPriceDsfStartForAddress = "1014603780154379309";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 96.15;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                // 0xaf1b9caf6f7ef9319cecc0a8be06bf28f6fc41a4
                else if (fromAddress == "0xaf1b9caf6f7ef9319cecc0a8be06bf28f6fc41a4") {
                  //lpPriceDsfStartForAddress = "997459030200000000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 1.01;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                // 0xc02597415abf3c3350a72186c13939c17452206a
                else if (fromAddress == "0xc02597415abf3c3350a72186c13939c17452206a") {
                  //lpPriceDsfStartForAddress = "997459030200000000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 5050;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x64abb3cd23699dc20024122a9826975724ecec7a
                else if (fromAddress == "0x64abb3cd23699dc20024122a9826975724ecec7a") {
                  //lpPriceDsfStartForAddress = "997459030200000000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 10.765655;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x04d7c5b8db54a1fdbad0bc87997a7aafa2d2debd
                /*else if (fromAddress == "0x04d7c5b8db54a1fdbad0bc87997a7aafa2d2debd") {
                  //lpPriceDsfStartForAddress = "1040124434552900000"; 
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 101;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }*/
                //0x92f1e4f6367982a75ad93890dd681e45acf4dae7
                else if (fromAddress == "0x92f1e4f6367982a75ad93890dd681e45acf4dae7") {
                  //lpPriceDsfStartForAddress = "1040473972695270000"; 
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 30;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0x8fb7e646b73ee22da2fdf8790c3a62d68d2710f3
                else if (fromAddress == "0x8fb7e646b73ee22da2fdf8790c3a62d68d2710f3") {
                  //lpPriceDsfStartForAddress = "1004495309461100000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 900;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                //0xa68be02a2b7ebecac84a6f33f2dda63b05911b95
                /*else if (fromAddress == "0xa68be02a2b7ebecac84a6f33f2dda63b05911b95") {
                  //lpPriceDsfStartForAddress = "1004495309461100000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 0;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }  */
                //0x04d7c5b8db54a1fdbad0bc87997a7aafa2d2debd
                else if (fromAddress == "0x04d7c5b8db54a1fdbad0bc87997a7aafa2d2debd") {
                  //lpPriceDsfStartForAddress = "1004495309461100000";
                  //lpPriceDsfStartForAddress = web3.utils.fromWei(lpPriceDsfStartForAddress, 'ether');
                  const FakeRealBalance = 1002.4588;
                  let FakeRealEstimateIncome2 = (parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - FakeRealBalance)*0.9985;
                  if ( FakeRealEstimateIncome2 <= 0.000001) {FakeRealEstimateIncome2 = 0.000001;}
                  const FakeRealEstimateIncome = FakeRealEstimateIncome2;
                  realBalanceStr = String(FakeRealBalance);
                  dispatch(setEstimatedIncome(FakeRealEstimateIncome.toFixed(6).toString()));
                }
                else {
                  dispatch(setEstimatedIncome(realEstimateIncome.toFixed(6).toString()));
                }
                
                if (realBalanceStr.length <= 8)
                {

                } else {
                  const intBallance = parseInt(realBalanceStr);
                  const amountInts = String(intBallance).length + 1 + 6;
                  realBalanceStr = realBalanceStr.substring(0, amountInts);
                  console.log(realBalanceStr);
                }
      
                const newDeal: Deal = {
                  icon: USDTIcon(),
                  name: "USDT",
                  count: realBalanceStr,
                }
                const newList: Array<Deal> = [newDeal];
                setList(newList);


                // ВРЕМЕННОЕ РЕШЕНИЕ
                
              });
            });

        });

      });
      } catch (error) {
        console.log(error);
      }
    }
  }

  React.useEffect(() => {
    getWithdrawBalance()
  }, [dropdownMenuWithdrawValue.count]);

  React.useEffect(() => {
    const sum = parseFloat(dropdownMenuWithdrawValue.count ? dropdownMenuWithdrawValue.count : "0") + parseFloat(estimatedIncome);
    dispatch(setReceiveSum(sum.toFixed(6).toString()));
  }, [dropdownMenuWithdrawValue.count]);

  return (
    <OutlinedCard avatar={dealData.avatar} title={dealData.title}>
      <Grid container spacing={{ xs: 2, sm: 4, lg: 9 }}>
        <Grid item xs={12} sm={6}>
          <DropdownMenuWithdraw 
            labelName={dropdownMenuWithdrawData.labelName} 
            menuItemsWithIcon={list} />
        </Grid>
        {
          dropdownMenuWithdrawValue.name ?
            <Grid item xs={12} sm={6} spacing={1}>
              <EstimatedIncome income={`${estimatedIncome} ${dropdownMenuWithdrawValue.name}`} />
            </Grid>
          : null
        }
      </Grid>
      {
        dropdownMenuWithdrawValue.name ? 
        <Grid container>
          <Grid item xs={10}>
            <Box mt={2.25}>
              <NotificationSmall icon={WalletIcon()} text={`${receiveSum} ${dropdownMenuWithdrawValue.name}`} helperText={"You will receive"} />
            </Box>
          </Grid>
        </Grid> 
        : null
      }

      <Box mt={dropdownMenuWithdrawValue.name ? 15.75 : 27.5 }>
        <TonalButton
          disabled={dropdownMenuWithdrawValue.name && dropdownMenuWithdrawValue.count === "0" ? true : false}
          text={dealData.buttonText}
          onClick={handleClickNext} />
      </Box>
    </OutlinedCard>
  );
}
