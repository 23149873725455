import { Header } from "./components/Header";
import { Container, ThemeProvider, Box, Grid } from "@mui/material";
import { theme } from "./theme";
import React, {
  useEffect,
} from "react";
import { CustomTabs } from "./components/CustomTabs";
import { tabsData } from "./data/TabsData";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init('faddfaeef8f90c4693220ca98ced9ed');

function App() {
  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      amplitude.track('Opened dApp page');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container maxWidth="lg" sx={{
        marginTop: "48px",
        "@media (min-width: 1200px)": {
          maxWidth: '1128px'
        }
      }}>
        <Box>
          <CustomTabs tabList={tabsData.tabList} panelList={tabsData.panelList} />
        </Box>

      </Container>
    </ThemeProvider>
  );
}

export default App;
